import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { H4 } from '@blueprintjs/core'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../style/contact.scss'

const Contact = () => (
    <Layout page='/contact'>
        <SEO title='Contact' />
        <div className='Contact'>
            <a href='mailto:hello@andrewkrippner.com'>
                <StaticImage
                    className='profile'
                    src='../images/profile-new.jpg'
                    alt=''
                />
            </a>
            <div>If you want my help to build something great:</div>
            <div>
                <a href='mailto:hello@andrewkrippner.com'>
                    <H4>hello@andrewkrippner.com</H4>
                </a>
                <a href='tel:760-216-0784'>
                    <H4>760.216.0784</H4>
                </a>
            </div>
        </div>
    </Layout>
)

export default Contact
